















































import {
  Component,
  Vue
} from 'vue-property-decorator';
import {
  Core
} from '@/store/core'
import {
  Auth
} from '@/store/auth'
import {
  User
} from '@/store/user'
import _ from 'lodash'
import {
  Web
} from '@/store/web'

import RawDataChart from '@/components/Super/AgencyRawDataChart.vue'
@Component({
  components: {
    RawDataChart
  },
})
export default class Home extends Vue {
  appSetting:any = {}
  chooseYear: any = '2563'
  chooseAgency:any = 7
  user:any = {}
  response:boolean = false
  private agency: any = null
  private years:any = []
  async created() {
    this.appSetting = await Core.getHttp(`/setting/app/`)
    this.chooseYear = this.appSetting.year
    this.user = await User.getUser()
    this.agency = await Core.getHttp(`/api/ita/v1/agency/`)
    this.years = await Core.getHttp(`/api/ita/v1/year/`)
    this.response = true;
  }

  async loadData(){
    await Web.switchLoad(true)
    this.response = false;
    setTimeout(() => {  console.log("World!"); }, 5000);
    await Web.switchLoad(false)
    this.response = true;
  }





  prePrint(){
    window.print();
  }

}
